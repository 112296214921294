
<template>
  <div class="app-container">
    <edit-companys/>
  </div>
</template>

<script>
import EditCompanys from '@/views/layout/modules/EditAgencyCompany'
export default {
  name: 'editCompany',
  components: {
    EditCompanys,
  },
  data(){
    return{

    }
  },
  methods: {

  }
}
</script>